@font-face {
    font-family: gilroy;
    src: url("../../fonts/gilroy-regular.otf");
}

@font-face {
    font-family: gilroy;
    src: url("../../fonts/gilroy-bold.otf");
    font-weight: bold;
}

html,
body {
    background-color: $bg-white;
    color: $grey;
    font-family: gilroy;
}

.App {
    padding: 2rem 1rem;
}

.shadowed {
    box-shadow: 0px 7px 20px 0px rgba(146, 166, 185, 0.20);
}

.rounded-radius {
    border-radius: 12px;
}

a {
    color: $kwit-green;
}

:root {
    --swiper-navigation-size: 20px !important;
    --swiper-navigation-top-offset: 50%;
    --swiper-navigation-sides-offset: 0 !important;
    --swiper-navigation-color: $grey;
}

h2, h3 {
    margin-block-start: 0;
}