.vertical-align-middle {
    vertical-align: middle;
}

.width {
    &--40pct {
        width: 40%;
    }
}

.no-margin {
    margin: 0;
}

.std-margin {
    &--right {
        margin-right: $std-margin;
    }
}

.horizontal-padding {
    padding: 0 2rem;
}

.cursor-pointer:hover {
    cursor: pointer;
}