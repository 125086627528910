.nrt {
    &__icon {
        margin-right: 13px;
        width: 30px;
        height: 30px;
    }

    &__row {
        @extend .row-space-between;
    }

    &__item {
        border-top: 1px solid #eee;
        padding-top: 10px;
     }
     
    &__item:first-child {
        margin-top: 10px;
        border-top: none;
        padding-top: 0;
     }
}