.row-space-between {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.row-space-around {
    display: flex;
    justify-content: space-around
}

.row {
    display: flex;

    &--align-items-center {
        @extend .row;
        align-items: center;
    }

    &--justify-content {
        &--space-around {
            @extend .row;
            justify-content: space-around;
        }
    }
}

.responsive-row {
    display: flex;
    width: 100%;

    &--center {
        @extend .responsive-row;
        justify-content: center;
    }

    &--centered-space-between {
        @extend .responsive-row;
        justify-content: space-between;
        align-items: center;
    }

    @media screen and (max-width: $md) {
        flex-direction: column;
    }

    &--align-items-center {
        @extend .responsive-row;
        align-items: center;
    }

    &--justify-content {
        &--space-around {
            @extend .responsive-row;
            justify-content: space-around;
        }
    }
}


.column {
    display: flex;
    flex-direction: column;

    &--flex-end {
        @extend .column;
        align-items: flex-end;
    }
}

.green-bordered-div {
    @extend .rounded-radius;
    border: solid 1px $kwit-green;
    padding: $std-padding;
}