.pill {
    border-radius: 25px;
    padding: 0.5rem;
    font-weight: bold;
    font-size: 9px;
}

.confidence-icon {
    width: 48px;
    height: 48px;
}