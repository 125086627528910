// TEXT SIZES
$tiny-text: 10px;
$small-text: 12px;
$medium-text: 14px;
$big-text: 18px;
$extra-large-text: 24px;

.kwit-green-text {
    font-weight: bold;
    color: $kwit-green;
    font-size: $medium-text;
    padding-top: 2px;
}

.kwit-extra-large-text {
    font-size: $extra-large-text;
}

.kwit-small-text {
    font-size: $small-text;
}

.kwit-tiny-text {
    font-size: $tiny-text;

    &--bold {
        @extend .kwit-tiny-text;
        font-weight: 700;
    }
}

.kwit-big-text {
    font-weight: bold;
    font-size: $big-text;
}

.kwit-medium-text {
    font-size: $medium-text;

    &--bold {
        @extend .kwit-medium-text;
        font-weight: 700;
    }
}

.kwit-medium-bold-text {
    @extend .kwit-medium-text;
    font-weight: 700;
}

.kwit-secondary-text {
    color: $light-grey;

    &--small {
        @extend .kwit-secondary-text;
        font-size: $small-text;
    }
}

.kwit-text-align {
    &--center {
        @extend .kwit-text-align;
        text-align: center;
    }
}


.kwit-error-text {
    margin-top: 2rem;
    font-style: italic;
}