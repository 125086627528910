.kwit-table {
    display: flex;
    flex-direction: column;
    align-content: space-between;
    

    & tr {
        display: flex;
        justify-content: space-between;
        margin-bottom: $std-margin;
    }

   &--bordered {
        width: 100%;    
        border-collapse: collapse;


        & tr:first-child td {
            border-top: 0;
            border-bottom: 0;
            font-style: italic;
        }

        & tr td:first-child {
            border-left: 0;
            border-bottom: 0;
        }

        & tr:not(:first-child) td {
            padding-top: 1rem;
        }

        & tr:last-child td {
            border-bottom: 0;

        }

        & tr td:last-child {
            border-right: 0;
        }

        & td {
            border: dashed #6C7984 0.5px;
            border-bottom: 0;
            border-top: 0;
        }
    }
}