.column {
    display: flex;
    flex-direction: column;
    align-items: center;

    &--centered {
        @extend .column;
        align-items: center;
        flex: 1;
    }  
}

.row {
    display: flex;
    flex-wrap: wrap;

    &--space-around {
        @extend .row;
        justify-content: space-around;
    }

    &--vertical-centered {
        @extend .row;
        align-items: center;
    }
}